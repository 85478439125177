/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useGoogleLogin } from '@react-oauth/google'
import { useCallback, useEffect, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import useDrivePicker from 'react-google-drive-picker'
import { useParams } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import { downloadazureFile, setazureDestination, uploadFile, uploadFileDemo } from '../../api'
import { useAuth } from '../auth'

type Props = {
  closeModal?: boolean
  source?: boolean
  text: string
  // doctype?: string
}
const UploadResumeForm: React.FC<Props> = ({ closeModal, source, text }) => {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()
  const { doctype } = useParams()

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [tag, setTag] = useState<string>('')
  const [company, setCompany] = useState<string>('ces')
  const [bank, setBank] = useState<string>('')
  const [pdfFile, setPdfFile] = useState<File>()
  const [fileName, setFileName] = useState<Array<string>>([])
  const [driveFiles, setDriveFiles] = useState<Array<string>>([])
  const [openPicker, data] = useDrivePicker()
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const loginToken = useGoogleLogin({
    // scope: "https://www.googleapis.com/auth/drive",
    // responseType: 'code',
    // accessType: 'offline',
    // flow: 'auth-code',
    // state: 'none',
    // client_id: "365521252893-1c16vfqbh1pd8f8c0r2mk09s86q30ovh.apps.googleusercontent.com",
    scope: 'https://www.googleapis.com/auth/drive.readonly',
    onSuccess: (tokenResponse) => {
      let payloadObj = {
        token: tokenResponse?.access_token,
        userName: currentUser?.username,
        userEmail: currentUser?.email,
      }
      openPicker({
        clientId: '530189915632-99uf6m53i80bslelck893eq2hsrf4qof.apps.googleusercontent.com',
        developerKey: 'AIzaSyAGC4-r178qkq8_q8ortjJBEXwNnMRvOwo',
        viewId: 'DOCS', // Set viewId to 'FOLDERS' to enable folder selection
        // viewMimeTypes: "application/vnd.google-apps.folder,application/json",
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        token: tokenResponse?.access_token,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
          if (data.action === 'cancel') {
          }
          if (data.docs.length == 1 && data.docs[0].type === 'folder') {
            const folderId = data.docs[0].id
            const type = data.docs[0].type
            if (source) {
              downloadazureFile({
                ...payloadObj,
                folder_id: folderId,
                type: type,
              }).then((res) => {
                window.location.reload()
              })
            } else {
              setazureDestination({
                ...payloadObj,
                folder_id: folderId,
                type: type,
              }).then((res) => {
                window.location.reload()
              })
            }
          }
          if (data.docs.length > 1) {
            let tempDriveFiles: string[] = []
            data.docs.map((doc) => {
              // Add other file IDs to the tempDriveFiles array if needed
              tempDriveFiles.push(doc.id)
            })
            downloadazureFile({
              ...payloadObj,
              folder_id: tempDriveFiles,
              type: 'file',
            }).then((res) => {
            })
            setDriveFiles([...tempDriveFiles])
          }
        },
      })
    },
    onError(errorResponse) {
    },
  })

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      // Handle file changes here
      if (acceptedFiles) {
        const fileList = Array.from(acceptedFiles)
        let tempFileNames: string[] = []
        acceptedFiles.map((file) => {
          tempFileNames.push(file.name)
        })
        setFileName([...tempFileNames])
        let seletedFile = acceptedFiles[0]
        setPdfFile(seletedFile)
        setSelectedFiles(fileList)
      }
    },
    []
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSubmit = (event: React.FormEvent) => {
    setLoading(true)
    event.preventDefault()
    let enableProcess = true

    if (selectedFiles.length > 0) {
      const formData = new FormData()
      let fileArr = []
      selectedFiles.forEach((file, index) => {
        formData.append('files', file, `${file.name}`)
      })
      formData.append('userName', currentUser ? currentUser.username : 'Singhi')
      formData.append('userEmail', currentUser ? currentUser.email : 'singhi@thirdeyedata.ai')

      if (currentUser?.username == 'Texisle') {
        formData.append('company', company)
      }

      if (currentUser?.username === 'HAL') {
        formData.append('subFolder', 'Servicing')
      }
      doctype ? formData.append('tag', doctype) : formData.append('tag', 'resume')
      // if (currentUser?.username === "Demo") {
      //   doctype ? formData.append("tag", doctype) : formData.append("tag", "handwritten")
      // } else {
      //   formData.append(
      //     "tag",
      //     currentUser?.username == "Texisle"
      //       ? "Invoice"
      //       : currentUser?.username == "HAL"
      //         ? "HAL Document"
      //         : "Contract"
      //   );
      // }

      // Use the formData object as needed, such as sending it via AJAX or API request
      // Example using fetch API:
      if (enableProcess) {
        if (currentUser?.username == 'HAL') {
          uploadFile(formData)
            .then((response: any) => {
              setLoading(false)
              window.location.reload()
              response.json()
            })
            .then((data) => { })
            .catch((error) => {
              // Handle error
            })
        } else {
          uploadFileDemo(formData)
            .then((response) => {
              setLoading(false)
              window.location.reload()
              response.json()
            })
            .then((data) => { })
            .catch((error) => {
              // Handle error
            })
        }
      }
    } else {
      setLoading(false)
      alert('You need at least one file uploaded')
    }
  }

  useEffect(() => {
    if (closeModal) {
      let tempArr: string[] = []
      setFileName(tempArr)
      setSelectedFiles([])
    }
  }, [closeModal])

  return (
    <div
      onClick={() => loginToken()}
      data-bs-dismiss='modal'
      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
    >
      {text}
    </div>
  )
}

export { UploadResumeForm }
